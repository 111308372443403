import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colour',
  templateUrl: './colour.component.html',
  styleUrls: ['./colour.component.scss']
})
export class ColourComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
