import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { LogoComponent } from './components/logo/logo.component';
import { ColourComponent } from './components/colour/colour.component';
import { TypographyComponent } from './components/typography/typography.component';
import { PhotographyComponent } from './components/photography/photography.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    LogoComponent,
    ColourComponent,
    TypographyComponent,
    PhotographyComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
